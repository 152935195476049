import React, { Component, Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import {ProtectedRoute} from "../protected.route";
import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";


const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {



    render() {
        const menu = routes.map((route, index) => {
            if(route.name === "Signin 1")
                return (route.component) ? (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                            <route.component {...props} />
                        )} />
                ) : (null);
            else
                return (route.component) ? (
                  <ProtectedRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                          <route.component {...props} />
                      )} />
              ) : (null);
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {menu}
                            <ProtectedRoute path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default withRouter(App);
